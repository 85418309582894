import React, { useState, useLayoutEffect } from "react"
import copyToClipboard from "copy-to-clipboard"
import { fakerBr } from 'js-brasil'
import { TextInput } from "@betrybe/ada-react/molecules"
import { Button } from "@betrybe/ada-react/atoms"
import Layout from "../../layouts"
import SEO from "../../layouts/seo"

export default (props) => {
  const [generatedCPF, setGeneratedCPF] = useState()

  const copy = () => {
    copyToClipboard(generatedCPF)
    setGeneratedCPF('Copiado!')
  }

  const generate = () => {
    setGeneratedCPF(fakerBr.cpf())
  }

  useLayoutEffect(() => {
    generate()
  }, [])

  return (
    <Layout location={props.location}>
      <div className="tool">
        <SEO title="Gerador de CPF" />
        <h1 className="tool-title">Gerador de CPF</h1>
        <p className="tool-paragraph">Clique no botão para gerar novos números:</p>
        <div className="tool-gerador-cpf-input">
          <TextInput value={generatedCPF} />
        </div>
        <div className="tool-gerador-cpf-buttons">
          <Button onClick={generate}>Gerar CPF</Button>
          <Button onClick={copy} kind="ghost">Copiar</Button>
        </div>
      </div>
      <div className="tool-description">
        <h2 className="tool-description-title">Gerador de CPF da Trybe</h2>
        <p className="tool-description-paragraph">
          Você sabe qual a utilidade de um gerador de CPF?
          No dia a dia da carreira de Desenvolvimento, pessoas profissionais utilizam esta ferramenta como forma de testar sistemas e apps online, ou simplesmente gerar placeholders (ou seja, informações fictícias pensadas para serem utilizadas de exemplo em textos ou formulários).
          Aproveite esta e outras ferramentas nossas em seus projetos! 😉
        </p>
      </div>
    </Layout>
  )
}
